import React from "react"

const Card = ({ children, extra, style, className }) => (
    <div className={`card ${className}`} style={style}>
        <div className="card-body">
            {children}
        </div>
        {extra && <div className="card-extra">
            {extra}
        </div>}
    </div>
)


export default Card
