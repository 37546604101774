import React from "react"

import Image from "../components/image"
import SEO from "../components/seo"
import Card from "../components/card"
import CopyableText from "../components/copyable-text"

const IndexPage = () => (
  <div className="animated fadeIn" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
    <SEO title="Start" />
    <Card style={{ textAlign: "center" }}>
      <p>
        <b>
          Torschitz & Schanes GmbH
        </b>
        <div>
          IT-Dienstleistung / Unternehmensberatung
        </div>
      </p>
      <p>
        <a href="https://www.google.com/maps/dir//Ungarnstra%C3%9Fe+12,+8350+Fehring,+%C3%96sterreich/@46.9350197,16.0117405,17z/data=!4m9!4m8!1m1!4e2!1m5!1m1!1s0x476f02b999bc7f1d:0x9f98cc001fa165a!2m2!1d16.0141064!2d46.9349711" target="__blank">
          Ungarnstraße 12, 8350 Fehring
        </a>
      </p>
      <div>
        <table>
          <tr>
            <td>
              <i class="material-icons">
                phone
              </i>
            </td>
            <td style={{ textAlign: "right" }}>
              <a href="tel:+43315533010">+43 3155 33010</a>
            </td>
          </tr>
          <tr>
            <td>
              <i class="material-icons">
                print
              </i>
            </td>
            <td style={{ textAlign: "right" }}>
              <div>+43 3155 33010 50</div>
            </td>
          </tr>
          <tr>
            <td>
              <i class="material-icons">
                email
              </i>
            </td>
            <td style={{ textAlign: "right" }}>
              <a href="mailto:office@torschitz-schanes.at">office@torschitz-schanes.at</a>
            </td>
          </tr>
          <tr>
            <td colspan="2" style={{ textAlign: "center" }}>
              Supportanfragen für Kunden:
            </td>
          </tr>
          <tr>
            <td>
              <i class="material-icons">
                email
              </i>
            </td>
            <td style={{ textAlign: "right" }}>
              <a href="mailto:support@torschitz-schanes.at">support@torschitz-schanes.at</a>
            </td>
          </tr>
        </table>



      </div>
    </Card>
  </div>
)

export default IndexPage
